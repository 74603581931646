import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { HeaderService } from './header.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class StringService {
  public strings: any = {};

  constructor(private cookie: CookieService,
              private http: HttpClient,
              private api: ApiService,
              private errorHandler: ErrorHandlerService,
              private headers: HeaderService) { }

  @Output() change: EventEmitter<any> = new EventEmitter();

  toggle(strings: any) {
    this.strings = strings;
    this.change.emit(strings);
  }

  public getStrings(language?: string): any {
    if (language === undefined || language === "") {
      language = this.cookie.get("language");
      if (language === undefined || language === "") {
        language = navigator.language;
        language = language.split('-')[0];
      }
    }
    this.cookie.set("language", language, 60 * 60 * 24 * 30, "/", "/", true, "None");
    return this.http.get<any>(this.api.GET_CONNECTION_STRINGS + "/" + language, this.headers.getOptions())
      .pipe(
        map(response => { this.errorHandler.setStrings(response); return response; }), 
        catchError(this.errorHandler.handleError)
      );
  }
}
