import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private strings: any = {};

  constructor() { }

  public handleError = (error: HttpErrorResponse) => {
    if (error.error !== undefined && error.error.message !== undefined) {
      if (error.error.message !== "NO_SESSION_FOUND") {
        if (this.strings !== undefined && this.strings[error.error.message] !== undefined) {
          alert(this.strings[error.error.message])
        } else {
          alert(error.error.message);
        }
      }
    }
    return throwError(error);
  }

  public setStrings(strings: any): void {
    this.strings = strings;
  }
}
