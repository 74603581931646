<div style="text-align:center">
    <img src="assets/img/fejlmelding-app.png" />
</div>
<div style="width:300px;height:200px;margin:0 auto;text-align:center;background:rgba(255,255,255,0.6); padding:10px;margin-top:100px;">
    <div style="width:150px;margin:0 auto;text-align:center;float:left;">
        <a href="{{ installer }}" download target="_blank">
            <img src="assets/img/loc.png" style="height:150px;width:150px" />
            Start Fejlmelding Admin
        </a>
    </div>
    <div style="width:150px;margin:0 auto;text-align:center;float:left;">
        <a [routerLink]="['/map']">
            <img src="assets/img/map.png" style="height:150px;width:150px;" />
            Åbn kort
        </a>
    </div>
</div>
