import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmRequestComponent } from './components/confirm-request/confirm-request.component';
import { StartComponent } from './components/start/start.component';
import { MapComponent } from './components/map/map.component';
import { AuthenticationGuard } from './guards/authentication.guard';


const routes: Routes = [
  { path: '', component: StartComponent },
  { path: 'start', component: StartComponent },
  { path: 'start/:code/:state', component: StartComponent },
  { path: 'confirm-request/:application/:token', component: ConfirmRequestComponent },
  { path: 'map', component: MapComponent, canActivate: [ AuthenticationGuard ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
