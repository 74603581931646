import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService {

  public loggedIn: boolean = false;

  constructor() {}

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle(loggedIn: boolean) {
    this.loggedIn = loggedIn;
    this.change.emit(this.loggedIn);
  }
}
