import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(arr: any[], filter: string): any[] {
    if (!arr) return;
    if (!filter) return arr;
    filter = filter.toLowerCase().replace(' ', '');
    return arr.filter(item => {
      try {
        return (item.locomotive.litra.litra + item.locomotive.name).toLowerCase().indexOf(filter) !== -1
      } catch (e) {return false;}
    })
  }
}
