import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PasswordService } from '../../services/password.service';
import { StringService } from '../../services/string.service';

@Component({
  selector: 'app-confirm-request',
  templateUrl: './confirm-request.component.html',
  styleUrls: ['../confirm-request/confirm-request.component.scss']
})
export class ConfirmRequestComponent implements OnInit {

  private token: string;
  public requestSent: boolean = false;

  constructor(private route: ActivatedRoute,
              private passwordService: PasswordService,
              public stringService: StringService) { }

  ngOnInit() {
  }

  public confirmRequest() {
    this.token = this.route.snapshot.paramMap.get('token');
    if (this.token !== undefined) {
      this.passwordService.confirmPasswordRequest(this.token).subscribe((response: any) => {
        this.requestSent = true;
      })
    }
  }
  
  public close() {
    window.close();
  }

}
