import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class ArraySortPipe implements PipeTransform {

  transform(items: any[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    items.sort((a: any, b: any) => {
      if (a[filter] < b[filter]) {
        return 1;
      } else if (a[filter] > b[filter]) {
        return -1;
      } else {
        return 0;
      }
    });
    return items;
  }

}
