import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  public installer = environment.installerName;

  constructor(private route: ActivatedRoute,
              private auth: AuthenticationService,
              public router: Router) { }

  ngOnInit() {
    let code = this.route.snapshot.paramMap.get('code');
    let state = this.route.snapshot.paramMap.get('state');
    if (code && state) {
      try {
        if (state.length % 4 !== 0) {
          for (let i=0; i<state.length % 4; i++) {
            state += '='
          }
        }
        if (state === this.auth.getState()) {
          let savedStateObj = JSON.parse(atob(this.auth.getState()));
          this.auth.requestAccessToken(code, savedStateObj.redirect)
        }
      } catch (e) {
      }
    }
  }

}
