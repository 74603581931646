<p>confirm-request works!</p>
<div class="password-container">
    <img src="assets/img/fejlmelding-app.png" id="logo">
    <p id="title">{{stringService.strings['CONFIRM_REQUEST']}}</p>
    <div class="animation-container" *ngIf="!requestSent">
      <div class="form-container">
          <div style="margin-top:20px;">{{stringService.strings['CONFIRM_REQUEST_EXPLANATION']}}</div>
          <button class="form-submit" (click)="confirmRequest()">{{stringService.strings['CONFIRM']}}</button>
          
      </div>
      <p class="stamp"><span><img src="assets/img/union-logo.png" style="height:10px;">&nbsp;&nbsp;</span>Powered by union</p>
    </div>
  
    <div class="animation-container" *ngIf="requestSent">
        <div id="form-container" style="font-size: 14px">
                <p>{{stringService.strings['REQUEST_CONFIRMED_EXPLANATION']}}</p>
            <input type="submit" class="form-submit" (click)="close()" value="{{stringService.strings['CLOSE_PAGE']}}">
        </div>
    </div>
</div>
  