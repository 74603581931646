import { Injectable } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { last } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  public center: google.maps.LatLngLiteral = {
    lat: 55.39594,
    lng: 10.38831
  };
  public zoom: number = 6;
  public options: google.maps.MapOptions = {
    mapTypeId: this.cookie.get('maptype') || 'hybrid',
    zoomControl: true,
    scrollwheel: true,
    streetViewControl: false
  };
  public markers: google.maps.Marker[] = [];
  public markerLabelColor: string = 'black';
  public colors = {
    Bil: 'blue',
    BR185: 'orange',
    EG: 'brown',
    MZ: 'magenta',
    default: 'red'
  }

  constructor(private cookie: CookieService) { }

  public buildMarkers(data: any[],
                      map: GoogleMap,
                      options: any) {
    this.clearMarkers();
    for (let elem of data) {
      let locationTime = moment(elem.location_time * 1000).local();
      let fifteenMinutesAgo = moment().local().subtract(15, 'minutes');
      let isRunning = (elem.speed && elem.speed > 5 && locationTime.isAfter(fifteenMinutesAgo))
      let lastElement = (elem.history.length === 1) ? elem.history[0] : elem.history[elem.history.length - 1];
      let rotation = (lastElement) ? this.getBearing(elem.lat, elem.lng, lastElement.lat, lastElement.lng) : 0;
      let marker = this.markers.find(e => e.getTitle() == elem.name);
      if (marker) {
        marker.setPosition({
          lat: elem.lat,
          lng: elem.lng
        })
        if (options.showLabels) {
          marker.setLabel({
            color: this.markerLabelColor,
            text: elem.name,
            fontSize: '12px',
            fontWeight: 'bold'
          })
        }
        marker.setIcon({
          path: (isRunning) ? google.maps.SymbolPath.BACKWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE,
          strokeColor: (this.colors[elem.locomotive.litra.litra]) ? this.colors[elem.locomotive.litra.litra] : this.colors.default,
          scale: (isRunning) ? 5 : 8,
          strokeWeight: 3,
          rotation: rotation
        })
        if (options.animate) marker.setAnimation(google.maps.Animation.DROP)
        marker.setTitle(elem.name);
      } else {
        marker = new google.maps.Marker({
          position: {
            lat: elem.lat,
            lng: elem.lng
          },
          label: options.showLabels ? {
            color: this.markerLabelColor,
            text: elem.name,
            fontSize: '12px',
            fontWeight: 'bold'
          } : null,
          icon: {
            path: (isRunning) ? google.maps.SymbolPath.BACKWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE,
            strokeColor: (this.colors[elem.locomotive.litra.litra]) ? this.colors[elem.locomotive.litra.litra] : this.colors.default,
            scale: (isRunning) ? 5 : 8,
            strokeWeight: 3,
            rotation: rotation
          },
          animation: (options.animate) ? google.maps.Animation.DROP : null,
          title: elem.name
        });
      }

      marker.addListener('click', () => {
        if (options.onSelect) {
          options.onSelect(elem);
        }
      })
      if (options.onHover) {
        marker.addListener('mouseover', () => {
          options.onHover(elem, marker);
        })
      }
      if (options.onHoverEnd) {
        marker.addListener('mouseout', () => {
          options.onHoverEnd();
        })
      }
      this.markers.push(marker)
    }
    return this.markers;
  }

  public updateMarkers(data: any[]) {
    for (let elem of data) {
      let isRunning = (elem.speed && elem.speed > 5)
      let lastElement = (elem.history.length === 1) ? elem.history[0] : elem.history[elem.history.length - 1];
      let rotation = (lastElement) ? this.getBearing(elem.lat, elem.lng, lastElement.lat, lastElement.lng) : 0;
      let marker = this.markers.find(e => (e.getLabel() as google.maps.MarkerLabel).text == elem.name);
      if (marker) {

      }
    }
  }

  private clearMarkers() {
    for (let m of this.markers) {
      if (m.getMap()) {
        m.setMap(null);
      }
    }
    this.markers = [];
  }

  private toRadians(degrees: number): number {
    return degrees * Math.PI / 180;
  }

  private toDegrees(radians: number): number {
    return radians * 180 / Math.PI;
  }

  private getBearing(startLat: number, startLng: number, endLat: number, endLng: number): number {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    endLat = this.toRadians(endLat);
    endLng = this.toRadians(endLng);

    let y = Math.sin(endLng - startLng) * Math.cos(endLat);
    let x = Math.cos(startLat) * Math.sin(endLat) - Math.sin(startLat) * Math.cos(endLat) * Math.cos(endLng - startLng);
    let bearing = Math.atan2(y, x);
    bearing = this.toDegrees(bearing);
    return (bearing + 360) % 360;
  }
}
