<input 
    #search
    type="search" 
    id="input-filter" 
    [(ngModel)]="filter" 
    (input)="router.navigate(['/map'], { queryParams: { order: order, direction: direction, filter: filter } })" 
    placeholder="{{stringService.strings['SEARCH']}}">
<div id="input-show-names">
    <label for="show-names">vis navne</label>
    <input 
        type="checkbox" 
        [(ngModel)]="showLabels" 
        id="show-names" 
        (change)=" router.navigate(['/map'], { queryParams: { labels: showLabels, order: order, direction: direction, filter: filter } })">
</div>

<div style="width: 60%;height:100%;float:left;">
    <google-map
        #map
        height="100%"
        width="100%"
        [zoom]="zoom"
        [center]="center"
        [options]="options"
        (maptypeidChanged)="mapTypeChanged()">
    </google-map>
</div>
<div style="position: relative;width:40%;height:100%;float:right;background:rgb(210,210,210);float:left;padding:5px;box-sizing: border-box;">
    <div *ngIf="selected" style="height:10%;background:rgba(250,250,250);padding:10px;overflow: visible;" class="no-wrap">
        
        <img src="assets/img/globe.webp" style="position:absolute;top:5px;left:5px;height:40px;cursor:pointer;" (click)="reset()" />
        <span *ngIf="selected" style="font-weight: bold;font-size: larger;">{{selected.name}}</span>
        <mat-slider 
            (input)="onSliderMoving($event)"
            [(ngModel)]="sliderValue" 
            *ngIf="selected" 
            min="0" 
            max="168"
            step=".016"
            inverse="true"
            thumbLabel
            [displayWith]="formatSliderLabel"
            style="line-height:20%;align-content:center;display:flex;align-items:center;z-index:99;height:30px;vertical-align: bottom;margin-left:40px;margin-right:40px;">
        </mat-slider>
    </div>
    <div style="overflow-y:auto;" [ngClass]="{'full-height': !selected, 'shortened': selected}">
        <table 
        mat-table matSort 
        (matSortChange)="router.navigate(['/map'], { queryParams: { order: $event.active, direction: $event.direction, labels: showLabels, filter: filter } })" 
        [dataSource]="trackers | filter: filter" 
        style="width: 100%;text-align:left;">
            <ng-container matColumnDef="type_color">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let tracker">
                    <span class="dot" [ngClass]="markerService.colors[tracker.locomotive.litra.litra] ? markerService.colors[tracker.locomotive.litra.litra] + '-background' : markerService.colors.default + '-background'"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="litra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="litra">Type</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    <span> {{tracker.locomotive.litra.litra}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="loc_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="loc_name">Navn</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    {{tracker.locomotive.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="tracker_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="tracker_name">GPS Navn</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    {{tracker.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="current_km">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="current_km">KM s.s. service</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    {{tracker.current_km}}
                </td>
            </ng-container>
            <ng-container matColumnDef="voltage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="voltage">Strømniveau</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    {{tracker.voltage_level}}
                </td>
            </ng-container>
            <ng-container matColumnDef="speed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="speed">Hastighed</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    <span *ngIf="tracker.speed">{{tracker.speed}} km/t</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="notification">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="notification">Start / Stop Notifikation</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    <input type="checkbox" [(ngModel)]="tracker.show_notifications" (change)="checkPermissions(tracker)">
                </td>
            </ng-container>
            <ng-container matColumnDef="history">
                <th mat-header-cell *matHeaderCellDef>Historik</th>
                <td mat-cell *matCellDef="let tracker" [ngClass]="(tracker.speed < 5) ? 'black-text' : 'white-text'">
                    <span *ngIf="selected?.id !== tracker.id"><button (click)="onSelect(tracker)">Åben</button></span>
                    <span *ngIf="selected?.id === tracker.id"><button (click)="reset()">Luk</button></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="headers"></tr>
            <tr 
                id="tracker{{row.id}}"
                mat-row style="height:auto;" 
                *matRowDef="let row; columns: headers" 
                [ngClass]="{'highlight': selected == row,
                            'green': selected != row && row.speed >= 5,
                            'grey': selected != row && row.speed < 5}"></tr>
        </table>
    </div>
</div>
