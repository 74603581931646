import { Component, OnInit } from '@angular/core';
import { StringService } from './services/string.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Fejlmelding';

  constructor(private stringService: StringService,
              private cookie: CookieService) {}

  ngOnInit() {
    this.stringService.getStrings(this.cookie.get("language")).subscribe((strings: Object) => { 
      this.stringService.toggle(strings); 
    })
  }
}
