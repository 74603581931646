import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { GoogleMap } from '@angular/google-maps';
import { StringService } from '../../services/string.service';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { TestDataService } from '../../services/test-data.service';
import { MarkerService } from '../../services/marker.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedInService } from 'src/app/services/logged-in.service';

interface Locomotive {
  name: number,
  current_km: number,
  current_km_time: Date,
  speed: number,
  tripmeter: number
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('search', { static: true }) search: ElementRef;
  public filterAttrs: string[] = ['locomotive.name'];
  public trackers: any = [];
  public filteredTrackers: any = [];
  public markers: any[] = [];
  public zoom: number = 7;
  public filter: string;
  public order: string;
  public direction: string;
  private path: google.maps.Polyline;
  private pathMarkers: any[] = [];
  public markerLabelColor: string = 'white';
  public sliderValue: number = 168;
  private historicMarker: google.maps.Marker;
  private sort: Sort;
  private hasNotificationPermission: boolean = false;
  public center: google.maps.LatLngLiteral = {
    lat: 55.39594,
    lng: 10.38831
  };
  public options: google.maps.MapOptions = {
    mapTypeId: this.cookie.get('maptype') || 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    streetViewControl: false
  };
  public selected: any;
  public headers = ['type_color', 'litra', 'loc_name', 'tracker_name', 'speed', 'current_km', 'voltage', 'notification', 'history'];
  private pathPoints: any[] = [];
  private infoWindow: google.maps.InfoWindow;
  public showLabels: boolean = false;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  constructor(private http: HttpClient,
              private api: ApiService,
              public stringService: StringService,
              private cookie: CookieService,
              private testDataService: TestDataService,
              public markerService: MarkerService,
              public router: Router,
              private route: ActivatedRoute,
              private loggedIn: LoggedInService) { }

  ngOnInit() {
    this.getTrackers(true);
    interval(10000).subscribe(() => {
      if (this.loggedIn.loggedIn) {
        this.getTrackers(false);
      }
    });
    /*this.trackers = JSON.parse(this.testDataService.testData);
    this.trackers.forEach((elem) => {
      elem.show_notifications = localStorage.getItem('NOTIFY_' + elem.name.replace(' ', '_')) === 'true'
    })
    this.filteredTrackers = this.trackers;
    this.markers = this.markerService.buildMarkers(
      this.filteredTrackers, 
      this.map, 
      {
        onSelect: (tracker) => { this.onSelect(tracker) },
        onHover: (tracker, marker) => { this.onHover(tracker, marker) },
        onHoverEnd: () => { this.closeInfoWindow() },
        showLabels: this.showLabels,
        animate: true
      }
    );*/
    this.route.queryParamMap.subscribe((params) => {
      this.filter = params.get('filter') || '';
      this.onFilter(this.filter);
      this.search.nativeElement.focus();
      if (params.get('order') && params.get('order') !== this.order || params.get('direction') && params.get('direction') !== this.direction) {
        this.order = params.get('order');
        this.direction = params.get('direction');
        this.sortTable({
          active: this.order,
          direction: this.direction || 'desc'
        })
      }
      if (params.get('labels')) {
        this.showLabels = params.get('labels') === 'true';
        this.setShowLabels(params.get('labels') === 'true')
      }
    })
  }

  ngAfterViewInit() {
    this.insertMarkers();
    this.hasNotificationPermission = Notification.permission === "granted";
  }

  private getTrackers(animate: boolean) {
    this.http.get(`${this.api.LOCATION}/trackers/enriched`).subscribe((trackers: any) => {
      this.trackers = trackers.reduce((filtered, t1) => {
        if (t1.locomotive) {
          let t2 = this.trackers.find(elem => elem.id === t1.id);
          console.log("compare name " + t1?.name + " new speed " + t1?.speed + " old speed " + t2?.speed);
          console.log("show notifications " + t2?.show_notifications);
          console.log("has permissions " + this.hasNotificationPermission);
          if (t2 && t2.show_notifications && this.hasNotificationPermission) {
            if (t1.speed <= 5 && t2.speed > 5) {
              let name = t2?.locomotive?.litra?.litra + " " + t2?.locomotive?.name; 
              new Notification(
                name + " er stoppet!", 
                { 
                  body: name + " stoppede kl. " + moment().local().format('HH:mm'),
                  requireInteraction: true,
                  image: "/assets/img/fejlmelding-app.png",
                  badge: "/assets/img/fejlmelding-app.png"
                }
              )
            } else if (t1.speed > 5 && t2.speed <= 5) {
              let name = t2?.locomotive?.litra?.litra + " " + t2?.locomotive?.name;
              new Notification(
                name + " er startet!",
                { 
                  body: name + " startede kl. " + moment().local().format('HH:mm'),
                  requireInteraction: true,
                  image: "/assets/img/fejlmelding-app.png",
                  badge: "/assets/img/fejlmelding-app.png"
                }
              )
            }
          }
          filtered.push(
            {
              id: t1.id, current_km: t1.current_km, current_km_time: t1.current_km_time,
              history: t1.history, lat: t1.lat, lng: t1.lng, location_time: t1.location_time,
              locomotive: t1.locomotive, locomotive_id: t1.locomotive_id, name: t1.name,
              physical_id: t1.physical_time, speed: t1.speed, tripmeter: t1.tripmeter, 
              voltage_level: t1.voltage_level, show_history: t2?.show_history, color: t2?.color,
              show_notifications: localStorage.getItem('NOTIFY_' + t1?.name?.replace(' ', '_')) === 'true' || false
            }
          )
        }
        return filtered;
      }, []);
      
      if (this.selected) {
        this.selected = this.trackers.find(elem => elem.id == this.selected.id)
        this.markers = this.markerService.buildMarkers(
          this.trackers, 
          this.map, 
          {
            onSelect: (tracker) => { this.onSelect(tracker) },
            onHover: (tracker, marker) => { this.onHover(tracker, marker) },
            onHoverEnd: () => { this.closeInfoWindow() },
            showLabels: this.showLabels,
            animate: animate
          }
        )
        let marker = this.markers.find(elem => elem.getTitle() == this.selected.name);
        marker.setPosition({
          lat: this.selected.lat,
          lng: this.selected.lng
        })
        this.getHistory(false);
      } else {
        this.onFilter(this.filter, false);
      }
      if (this.sort) this.sortTable(this.sort)
      this.mapTypeChanged();
    })
  }

  public getHistory(fitToBounds: boolean = false) {
    let t = this.trackers.find((elem: any) => { return elem.id === this.selected.id });
    if (t && !t.show_history) {
      this.http.get(`${this.api.LOCATION}/trackers/history/${this.selected.id}`).subscribe((history: any) => {
        if (history && history instanceof Array && history.length > 0) {
          this.selected['history'] = history
          this.showHistory();
          if (fitToBounds) {
            var bounds = new google.maps.LatLngBounds();
            for (let h of this.selected.history) {
              bounds.extend(h);
            }
            this.map.googleMap.fitBounds(bounds);
            this.showHistory()
          }
        }
      })
      if (fitToBounds) {
        var bounds = new google.maps.LatLngBounds();
        for (let h of this.selected.history) {
          bounds.extend(h);
        }
        this.map.googleMap.fitBounds(bounds);
        this.showHistory()
      }
      
    } else {
      if (t) {
        t.show_history = false;
        this.unhideAll();
      }
    }
  }

  /*public buildClusters() {
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
      this.markerCluster.setValues(this.markers); 
      //this.markerCluster.setMap(null);
    }
    this.markerCluster = new MarkerClusterer(this.map._googleMap, this.markers, {
      gridSize: 25,
      styles: [
        {
          url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
          textColor: 'white',
          height: 50,
          width: 50,
          anchorText: [19, 27],
          textSize: 14
        }
      ]
    });
  }*/

  public insertMarkers() {
    for (let m of this.markers) {
      if (m && this.map) m.setMap(this.map.googleMap);
    }
  }

  private getContent(tracker): string {
    console.log(tracker);
    return `
      <div>${tracker?.locomotive?.litra?.litra}</div>
      <div><b>${(tracker?.locomotive?.litra?.litra === 'Bil') ? tracker?.name : tracker?.locomotive?.name}</b></div>
      <div>${moment.unix(tracker?.timestamp || tracker?.location_time).format('YYYY-MM-DD HH:mm')}</div>
      <div>${tracker?.speed} km/t</div>
    `;
  }

  public setShowLabels(showLabels) {
    this.markers = this.markerService.buildMarkers(
      this.filteredTrackers, 
      this.map, 
      {
        onSelect: (tracker) => { this.onSelect(tracker) },
        onHover: (tracker, marker) => { this.onHover(tracker, marker) },
        onHoverEnd: () => { this.closeInfoWindow() },
        showLabels: this.showLabels
      }
    );
    this.insertMarkers();
  }

  public setCenter(e: any) {
    let center = {
      lat: e.lat,
      lng: e.lng
    };
    this.map.center = new google.maps.LatLng(center);
    
  }

  public setZoom(value: number) {
    this.map.zoom = value;
  }

  public onFilter(filter: string, animate: boolean = false) {
    this.filteredTrackers = this.trackers;
    if (filter) {
      filter = filter.toLowerCase().replace(' ', '');
      this.filteredTrackers = this.trackers.filter(item => {
        try {
          return (item.locomotive.litra.litra + item.locomotive.name).toLowerCase().indexOf(filter) !== -1
        } catch (e) {return false;}
      })
    }
    
    this.markers = this.markerService.buildMarkers(
      this.filteredTrackers, 
      this.map, 
      {
        onSelect: (tracker) => { this.onSelect(tracker) },
        onHover: (tracker, marker) => { this.onHover(tracker, marker) },
        onHoverEnd: () => { this.closeInfoWindow() },
        showLabels: this.showLabels,
        animate: animate
      }
    );
    this.insertMarkers();
  }

  public unhideAll() {
    for (let m of this.markers) {
      m.setVisible(true);
    }
  }
  private hideAll(exception) {
    let marker;
    for (let m of this.markers) {
      if (!exception || m.getTitle() != exception.name) {
        m.setVisible(false);
      } else marker = m;
    }
    this.insertMarkers()
  }

  private closeInfoWindow() {
    if (this.infoWindow) this.infoWindow.close();
  }

  public reset() {
    this.selected=undefined;
    this.setCenter({lat: 55.39594, lng: 10.38831});
    this.setZoom(6);
    this.insertMarkers();
    this.unhideAll();
    if (this.infoWindow) {
      this.closeInfoWindow();
      this.infoWindow = undefined;
    }
    if (this.historicMarker) {
      this.historicMarker.setMap(null);
      this.historicMarker = undefined;
    }
    if (this.path) {
      this.path.setMap(null);
      this.path = undefined;
    }
  }

  private showHistory() {
    this.hideAll(this.selected);
    this.pathPoints = [];
    let cutoff = moment().subtract(168, "hours").add(this.sliderValue, "hours");
    for (let i=0; i<this.selected.history.length; i++) {
      let timestamp = moment.unix(this.selected.history[i].timestamp);
      if (timestamp.isAfter(cutoff)) {
        this.pathPoints.push(this.selected.history[i]);
      }
    }
    if (this.path) {
      try {
        this.path.setPath(this.pathPoints)
      } catch (e) {}
    } else {
      this.path = new google.maps.Polyline({
        path: this.pathPoints,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
      
      this.path.setMap(this.map.googleMap);
    }
    let marker: google.maps.Marker = this.markers.find(elem => elem.getTitle() == this.selected.name)
    if (this.pathPoints.length > 0) {
      let point = this.pathPoints[0];
      let icon: any = marker.getIcon();
      let isRunning = point.speed >= 6;
      let rotation = 0;
      if (this.pathPoints.length > 1) {
        let lastElement = this.pathPoints[1];
        rotation = this.getBearing(lastElement.lat, lastElement.lng, point.lat, point.lng);
      }
      if (this.historicMarker) {
        this.historicMarker.setPosition(point);
        this.historicMarker.setIcon({
          path: (isRunning) ? google.maps.SymbolPath.BACKWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE,
          strokeColor: this.markerLabelColor,
          scale: icon.scale,
          strokeWeight: 3,
          rotation: (isRunning) ? rotation : undefined
        })
      } else {
        this.historicMarker = new google.maps.Marker({
          position: marker.getPosition(),
          icon: {
            path: (isRunning) ? google.maps.SymbolPath.BACKWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE,
            strokeColor: this.markerLabelColor,
            scale: icon.scale,
            strokeWeight: 3,
            rotation: (isRunning) ? rotation : undefined
          },
          map: marker.getMap()
        })
      }
      
      
      
      let infoPoint = (this.pathPoints.length > 0) ? this.pathPoints[0] : this.selected;
      infoPoint['name'] = this.selected.name;
      infoPoint['locomotive'] = this.selected.locomotive;
      if (this.infoWindow) {
        this.infoWindow.setContent(this.getContent(infoPoint));
        this.infoWindow.setPosition(this.historicMarker.getPosition());
        this.infoWindow.open(this.map.googleMap, this.historicMarker);
      } else {
        this.infoWindow = new google.maps.InfoWindow({
          content: this.getContent(infoPoint),
          maxWidth: 320
        })
        this.infoWindow.open(this.map.googleMap, this.historicMarker);
      }
      
    } else {
      if (this.historicMarker) {
        this.historicMarker.setMap(null);
        this.historicMarker = undefined;
      }
      if (this.infoWindow) {
        this.infoWindow.setContent(this.getContent(this.selected));
        this.infoWindow.setPosition(marker.getPosition());
        this.infoWindow.open(this.map.googleMap, marker);
      } else {
        this.infoWindow = new google.maps.InfoWindow({
          content: this.getContent(marker),
          maxWidth: 320
        })
        this.infoWindow.open(this.map.googleMap, marker);
      }
    }
  }

  private onHover(tracker: any, marker: google.maps.Marker) {
    if (this.infoWindow) {
      this.infoWindow.setContent(this.getContent(tracker));
      this.infoWindow.setPosition(marker.getPosition());
      this.infoWindow.open(this.map.googleMap, marker);
    } else {
      this.infoWindow = new google.maps.InfoWindow({
        content: this.getContent(tracker),
        maxWidth: 320
      })
      this.infoWindow.open(this.map.googleMap, marker);
    }
  }

  private toRadians(degrees: number): number {
    return degrees * Math.PI / 180;
  }

  private toDegrees(radians: number): number {
    return radians * 180 / Math.PI;
  }

  private getBearing(startLat: number, startLng: number, endLat: number, endLng: number): number {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    endLat = this.toRadians(endLat);
    endLng = this.toRadians(endLng);

    let y = Math.sin(endLng - startLng) * Math.cos(endLat);
    let x = Math.cos(startLat) * Math.sin(endLat) - Math.sin(startLat) * Math.cos(endLat) * Math.cos(endLng - startLng);
    let bearing = Math.atan2(y, x);
    bearing = this.toDegrees(bearing);
    return (bearing + 360) % 360;
  }

  public mapTypeChanged() {
    if (this.map && this.map.googleMap) {
      switch (this.map.googleMap.getMapTypeId()) {
        case 'roadmap':
        case 'terrain':
          this.markerLabelColor = 'black';
          break;
        default:
          this.markerLabelColor = 'white';
          break;
      }
      for (let m of this.markers) {
        if (this.showLabels) {
          let label = m.getLabel();
          m.setLabel({
            text: label.text,
            color: this.markerLabelColor,
            fontSize: label.fontSize,
            fontWeight: label.fontWeight
          })
        }
      }
      if (this.historicMarker) {
        let icon: any = this.historicMarker.getIcon();
        this.historicMarker.setIcon({
          path: icon.path,
          strokeColor: this.markerLabelColor,
          scale: icon.scale,
          strokeWeight: icon.strokeWeight,
          rotation: icon.rotation
        })
      }
      this.cookie.set("maptype", this.map.googleMap.getMapTypeId(), 60 * 60 * 24 * 30, "/", "/", true, "None");
    }
  }

  public scrollTo(id: string) {
    let el = document.getElementById(id);
    if (el) el.scrollIntoView({ behavior: 'smooth' })
  }

  public history(tracker) {
    if (tracker.show_history) {
      this.selected = undefined;
      tracker.show_history = false;
      this.insertMarkers();
      this.unhideAll();
    } else {
      this.insertMarkers();
      this.unhideAll();
      for (let t of this.trackers) t.show_history = false;
      this.selected = tracker;
      this.sliderValue = 168;
      this.getHistory(tracker);
      tracker.show_history = true;
      this.scrollTo('tracker' + tracker.id);
      this.setCenter({ lat: tracker.lat, lng: tracker.lng })
    }
  }

  public onSelect(tracker) {
    this.scrollTo('tracker' + tracker.id);
    this.selected = tracker;
    this.sliderValue = 168;
    this.getHistory(true);
    this.setCenter({
      lat: tracker.lat,
      lng: tracker.lng
    });
    this.setZoom(12);
    tracker.show_history = true;
    let marker = this.markers.find(elem => elem.getTitle() == this.selected.name)
    if (this.infoWindow) {
      this.infoWindow.setContent(this.getContent(tracker));
      this.infoWindow.setPosition(marker.getPosition());
      this.infoWindow.open(this.map.googleMap, marker);
    } else {
      this.infoWindow = new google.maps.InfoWindow({
        content: this.getContent(marker),
        maxWidth: 320
      })
      this.infoWindow.open(this.map.googleMap, marker);
    }
  }
  public locate(tracker) {
    this.setCenter({id: tracker.id, lat: tracker.lat, lng: tracker.lng});
    this.selected = tracker;
    this.scrollTo('tracker' + tracker.id)
  }
  public sortTable(sort: any) {
    this.sort = sort;
    const data = this.trackers.slice();
    if (!sort.active || sort.direction === '') {
      this.trackers = data;
      return;
    }
    this.trackers = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'tracker_name': return this.compare(a.name, b.name, isAsc);
        case 'litra': return this.compare(a.locomotive.litra.litra, b.locomotive.litra.litra, isAsc);
        case 'loc_name': return this.compare(a.locomotive.name, b.locomotive.name, isAsc);
        case 'speed': return this.compare(a.speed, b.speed, isAsc);
        default: return 0;
      }
    })
  }
  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  formatSliderLabel(value: number) {
    return moment().subtract(168, "hours").add(value, "hours").format('YYYY-MM-DD HH:mm')
  }
  public onSliderMoving(event) {
    this.sliderValue = event.value;
    if (this.selected.history && this.selected.history.length > 1) {
      this.showHistory();
    }
  }

  checkPermissions(tracker) {
    if (tracker.show_notifications && !this.hasNotificationPermission) {
      Notification.requestPermission((permission: string) => {
        this.hasNotificationPermission = permission === 'granted';
        tracker.show_notifications = this.hasNotificationPermission;   
      })
    }
    localStorage.setItem('NOTIFY_' + tracker.name.replace(' ', '_'), tracker.show_notifications.toString());
  }
  
}
