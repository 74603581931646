import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})

export class PasswordService {

  constructor(private http: HttpClient,
              private api: ApiService,
              private headers: HeaderService,
              private errorHandler: ErrorHandlerService) { }

  public confirmPasswordRequest(token: string) {
    let data = { token: token };
    return this.http.post<any>(this.api.CONFIRM_PASSWORD_REQUEST, data, this.headers.getOptions())
      .pipe( catchError(this.errorHandler.handleError) )
  }
}
